import {useEffect, useState} from "react";
import { getFormattedPrice } from "../../functions";
import { useSelector } from "react-redux";

export default function ProductBlock({productsGroup, handleOrderProduct}) {
    // return (<div className="m-5 bg-black text-white">{JSON.stringify(productsGroup)}</div>);
    const appInfo = useSelector((state) => state.appInfo);

    const image = productsGroup[0].image? productsGroup[0].image: `/images/${appInfo.storeId}/brand-logo.webp`;

    const getResponsiveTextClass = (text) => {
        if (text.length > 45) return "text-[6px] lg:text-[12px]";
        if (text.length > 35) return "text-[8px] lg:text-[16px]";
        if (text.length > 25) return "text-[10px] lg:text-[18px]";
        if (text.length > 20) return "text-[12px] lg:text-[20px]";
        if (text.length > 15) return "text-[12px] lg:text-[20px]";
        return "text-[14px] lg:text-[24px]";
    };

    return (
        <div className="w-full shadow-xl rounded-[10px] hover:opacity-80 hover:cursor-pointer" onClick={() => handleOrderProduct(productsGroup)}>
            <div
                className="h-[150px] xs:h-[120px] md:h-[300px] w-full bg-cover bg-center rounded-t-[10px]"
                style={{
                    backgroundImage:
                        `url('${image}')`,
                }}
            ></div>
            <div className="p-2">
                <div className={`font-bold h-[95px] md:h-[80px] ${getResponsiveTextClass(productsGroup[0].groupMaterialName)} leading-[20px] md:leading-[25px]`}>
                    {productsGroup[0].groupMaterialName}
                    <div className="text-[10px] md:text-[14px] font-medium">{productsGroup[0].description}</div>
                </div>
                <div className="text-red-600 font-bold text-[16px] md:text-[23px] flex justify-between items-center">
                    {getFormattedPrice(productsGroup[0].unitPrice)}
                    <button aria-label={productsGroup[0].materialId} className="h-[20px] w-[20px] md:h-[30px] md:w-[70px] rounded-[5px] font-bold bg-red-600 text-white hover:opacity-80 text-[16px] flex items-center justify-center"><i className="fa-solid fa-plus"></i></button>
                </div>
            </div>
        </div>
    );
}
