import React from "react";

export default function DinningTable() {
  return (
    <svg
      fill="white"
      viewBox="0 0 48 48"
      className="w-4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Table">
        <path d="m3 16v22a2 2 0 0 0 2 2h1.1a2 2 0 0 0 1.989-1.779l1.811-16.221h28.2l1.8 16.22a2 2 0 0 0 2 1.78h1.1a2 2 0 0 0 2-2v-22z" />
        <path d="m46 14h-44a2 2 0 0 1 -2-2v-2a2 2 0 0 1 2-2h44a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2z" />
      </g>
    </svg>
  );
}
