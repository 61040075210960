import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchProduct from "../Search/SearchProduct";
import Axios from "../../functions/axios";
import LoadingFullScreen from "../Loading/LoadingFullScreen";
import ProductsHistoryModal from "../Modal/ProductsHistoryModal";
import { useSearchParams } from 'react-router-dom';


export default function Nav() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSearch, setShowSearch] = useState(false);
  const [showProductHistoryModal, setShowProductHistoryModal] = useState(Boolean(searchParams.get('showProductHistoryModal')));
  // const [paymentMethod, setPaymentMethod] = useState(2);
  const [loading, setLoading] = useState(false);
  const productHistory = useSelector((state) => state.productHistory);
  const appInfo = useSelector((state) => state.appInfo);

  const handleShowSearch = () => {
    setShowSearch(true);
  }

  const handleUnshowSearch = () => {
    setShowSearch(false);
  };

  const handleCloseProductHistoryModal = () => {
    setShowProductHistoryModal(false);
    if (searchParams.get('showProductHistoryModal')) {
      searchParams.delete('showProductHistoryModal');
      setSearchParams(searchParams);
    }
  };

  // const handlePayment = () => {
  //   // if (productHistory.orderId)
  // };

  // const handlePaymentMoMo = () => {
  //   const orderId = productHistory
  //   const params = {
  //     orderId: orderId,
  //     repay: false,
  //     appId: parseInt(appInfo.appId), //web
  //     redirectUrl: window.location.origin +
  //       `/payment/completed?paymentMethod=${paymentMethod}`,
  //   };
  //   const stringParams = new URLSearchParams(params).toString();
  //   Axios.post(`/api/ProductOnline/PayByMomo?${stringParams}`)
  //     .then((response) => {
  //       console.log(response);
  //       const url = response.data.data?.res;
  //       if (!url) return alert("Có lỗi xảy ra vui lòng quay lại sau");
  //       window.location.href = url;
  //     })
  //     .catch(() => {
  //       alert("Có lỗi xảy ra vui lòng quay lại sau");
  //     })
  //     .finally(() => setLoading(false));
  // };

  return (
    <>
      <LoadingFullScreen loading={loading} />
      <div className="w-full bg-red-600 text-white text-sm text-center pt-1">Bàn {appInfo.tableStr}</div>
      <nav className="flex justify-between items-center px-5 h-[70px] bg-gray-900 relative">
        <i className="fa-solid fa-bars opacity-0"></i>
        <Link
          to={'/'}
          className="absolute text-gray-800 font-bold flex items-center justify-center text-2xl left-5 lg:left-16"
        >
          {/* <img src={`/images/2/brand-logo.webp`} alt='logo' className='h-[50px] mr-1 rounded-full'></img> */}
          <img src={`/images/common/app-logo.png`} alt='logo' className='h-[50px] ml-1'></img>
        </Link>
        <div className={`absolute top-0 left-0 h-full lg:relative w-[calc(96%-70px)] lg:w-1/2 px-2 text-[20px] z-20 lg:flex items-center justify-center ${showSearch ? 'flex' : 'hidden'}`}>
          <SearchProduct />
        </div>
        {(showSearch === false) ? (
          <button
            onClick={handleShowSearch}
            className="fa-solid fa-magnifying-glass absolute right-[60px] hover:opacity-80 text-yellow-300 lg:hidden"
          ></button>
        ) : (
          <button
            onClick={handleUnshowSearch}
            type="button"
            aria-label="Close-search"
            className="fa-solid fa-xmark absolute right-[60px] hover:opacity-80 text-red-300 lg:hidden"
          ></button>
        )}
        <button className={`fa-solid fa-clock-rotate-left text-red-600 relative ${productHistory.length > 0 ? 'animate-pulse' : ''}`} onClick={() => setShowProductHistoryModal(true)}>
          <button className="absolute h-5 w-5 text-[12px] -top-3 -right-3 text-white bg-red-600 rounded-full">{productHistory.length}</button>
        </button>
      </nav>
      <ProductsHistoryModal show={showProductHistoryModal} onClose={handleCloseProductHistoryModal} productHistory={productHistory} />
    </>
  );
}
