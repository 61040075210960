import { createSlice } from '@reduxjs/toolkit'

const initialState = [];

export const categoriesWithProductsSlice = createSlice({
  name: 'categoriesWithProducts',
  initialState,
  reducers: {
    initCategoriesWithProducts: (state, action) => {
      if (Array.isArray(action.payload))
        return action.payload
      else 
        return state;
    }
  },
})

// Action creators are generated for each case reducer function
export const { initCategoriesWithProducts } = categoriesWithProductsSlice.actions

export default categoriesWithProductsSlice.reducer