import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const productsInCartSlice = createSlice({
  name: "productsInCart",
  initialState,
  reducers: {
    initProductsInCart: (state, action) => {
      let productsInCart = action.payload.products;
      let appInfo = action.payload.appInfo;
      //lọc tableStr để lấy ra các sản phẩm trong giỏ hàng theo đúng bàn
      productsInCart = productsInCart.filter((product) => {
        return JSON.stringify(product.appInfo) === JSON.stringify(appInfo);
      });
     
      return productsInCart;
    },

    addProductToCart: (state, action) => {
      const productOrdering = action.payload;
      const _productsInCart = JSON.parse(JSON.stringify(state)); //deep copy
      const index = state.findIndex(
        (product) => product.materialId === productOrdering.materialId
      );
      if (index !== -1) {
        _productsInCart[index].quantity += productOrdering.quantity;
      } else {
        _productsInCart.push({...productOrdering, quantity: parseInt(productOrdering.quantity)});
      };
      localStorage.setItem("productsInCart", JSON.stringify(_productsInCart));
      return _productsInCart;
    },

    changeQuantityInCart: (state, action) => {
      let { materialId, quantity } = action.payload;
      quantity = parseInt(quantity);
      if (isNaN(quantity) || quantity < 0) quantity = 0;
      let productsInCart = state.map((product) => {
        if (product.materialId === materialId) {
          return {
            ...product,
            quantity: parseInt(quantity),
          };
        }
        return product;
      });
      localStorage.setItem("productsInCart", JSON.stringify(productsInCart));
      return productsInCart;
    },

    decreaseQuantityInCart: (state, action) => {
      const materialId = action.payload;
      let productsInCart = state
        .map((product) => {
          if (product.materialId === materialId) {
            return {
              ...product,
              quantity: product.quantity - 1,
            };
          }
          return product;
        })
        .filter((product) => product.quantity > 0);
      localStorage.setItem("productsInCart", JSON.stringify(productsInCart));
      return productsInCart;
    },

    increaseQuantityInCart: (state, action) => {
      const materialId = action.payload;
      const productsInCart = state.map((product) => {
        if (product.materialId === materialId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });
      localStorage.setItem("productsInCart", JSON.stringify(productsInCart));
      return productsInCart;
    },

    removeProductsInCart: (state, action) => {
      const productID = action.payload;
      const productsInCart = state.filter(
        (product) => product.materialId !== productID
      );
      localStorage.setItem("productsInCart", JSON.stringify(productsInCart));
      return productsInCart;
    },

    clearCart: () => {
      localStorage.removeItem("productsInCart");
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initProductsInCart,
  addProductToCart,
  changeQuantityInCart,
  decreaseQuantityInCart,
  increaseQuantityInCart,
  removeProductsInCart,
  clearCart,
} = productsInCartSlice.actions;

export default productsInCartSlice.reducer;
