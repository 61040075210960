import { getFormattedPrice } from '../../functions';
import { useSelector, useDispatch } from 'react-redux';
import {
    changeProductsOrdering,
    increaseQuantityOrdering,
    decreaseQuantityOrdering,
    changeQuantityOrdering,
    removeProductsOrdering,
    changeDecriptionOrdering
} from '../../reducers/Product/ProductsOrderingSlice';
import { addProductToCart } from '../../reducers/Product/ProductsInCartSlice';
import { removeProductsGroupOrdering } from '../../reducers/Product/ProductsGroupOrderingSlice';

export default function ProductOrderingBox() {
    const productsGroupOrdering = useSelector((state) => state.productsGroupOrdering);
    const productOrdering = useSelector((state) => state.productOrdering);
    const appInfo = useSelector((state) => state.appInfo);
    const dispatch = useDispatch();

    const handleDecreaseQuantityOrder = () => {
        if (productOrdering.quantity > 0) dispatch(decreaseQuantityOrdering());
    };

    const handleIncreaseQuantityOrder = () => {
        dispatch(increaseQuantityOrdering());
    };

    const handleRemoveProductOrdering = () => {
        dispatch(removeProductsOrdering());
        dispatch(removeProductsGroupOrdering());
    };

    const handleAddProductToCart = (productOrdering) => {
        //Thêm tableStr vào productOrdering
        let productOrderingWithAppInfo = { ...productOrdering, appInfo: appInfo };
        handleRemoveProductOrdering()
        if (productOrdering.quantity <= 0) return;
        dispatch(addProductToCart(productOrderingWithAppInfo));
    };

    const image = productOrdering.image ? productOrdering.image : `/images/${appInfo.storeId}/brand-logo.webp`;

    const handleProductQuantityOnChange = (e) => {
        dispatch(changeQuantityOrdering(e.target.value));
    };

    const handleDescriptionOnChange = (e) => {
        dispatch(changeDecriptionOrdering(e.target.value));
    };

    if (!productOrdering.name) return null;

    return (
        <div
            className="fixed top-0 bottom-0 h-screen w-full bg-black bg-opacity-40 z-20"
            onClick={() => handleRemoveProductOrdering()}
        >
            <div
                className="fixed bottom-0 bg-white w-full rounded-t-[15px] z-20 animate__animated animate__slideInUp animate__faster"
                onClick={(event) => event.stopPropagation()}
            >
                <button
                    className="absolute top-3 right-3 text-[25px] text-gray-600 fa-sharp fa-solid fa-circle-xmark z-10 hover:opacity-80"
                    onClick={() => handleRemoveProductOrdering()}
                ></button>

                <div className='bg-gray-100 p-3'>
                    <div className="flex">
                        <div className="pr-5">
                            <div
                                className="h-[110px] w-[110px] mx-auto bg-cover bg-center rounded-lg border-2 border-red-600"
                                style={{ backgroundImage: `url('${image}')` }}
                            ></div>
                        </div>
                        <div className="flex flex-wrap items-center">
                            
                            <div className="flex pt-1 pb-2 w-full font-bold text-[22px]">
                                {productOrdering.name} 
                            </div>
                            
                            <div className="flex w-full pb-3">
                                <button
                                    onClick={handleDecreaseQuantityOrder}
                                    className="border-2 text-red-600 h-8 w-8 flex items-center justify-center hover:opacity-60"
                                >
                                    <i className="text-[12px] fa-solid fa-minus"></i>
                                </button>
                                <input
                                    className="w-16 h-8 text-center hide-input-number-arrow"
                                    type="number"
                                    value={productOrdering.quantity}
                                    min={0}
                                    onChange={handleProductQuantityOnChange}
                                ></input>
                                <button
                                    onClick={handleIncreaseQuantityOrder}
                                    className="border-2 text-red-600 h-8 w-8 flex items-center justify-center hover:opacity-60"
                                >
                                    <i className="text-[12px] fa-solid fa-plus"></i>
                                </button>
                            </div>

                            {productsGroupOrdering.length > 1 &&
                            <div className='w-full flex flex-wrap my-2'>
                                {productsGroupOrdering.map((product) => {
                                    return (
                                        <button onClick={() => dispatch(changeProductsOrdering(product))} className={`px-2 mx-2 rounded border ${product.materialId === productOrdering.materialId? 'bg-red-600 text-white' : 'bg-white text-black'}`}>
                                            {product.opt01} {product.opt02? `- ${product.opt02}` : ''}
                                        </button>
                                    )
                                })}
                            </div>}
                            <div className="flex items-center font-bold text-red-600 text-[25px]">
                                {getFormattedPrice(
                                    productOrdering.unitPrice * productOrdering.quantity
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='pt-2'>
                        <input name='description' placeholder='Ghi chú thêm...' value={productOrdering.description} onChange={handleDescriptionOnChange} className='w-full h-[40px] px-2' />
                    </div>
                </div>
                <div className="flex items-center justify-center py-3">
                    <button
                        className="bg-red-600 text-white font-bold w-1/2 py-1 rounded-[10px] hover:opacity-80"
                        onClick={() => handleAddProductToCart(productOrdering)}
                    >
                        Add <i className="fa-solid fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}
