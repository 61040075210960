import './App.css';
import 'animate.css';
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Home from './pages/Home';
import PageNotFound from './pages/404';
import PaymentCompleted from './pages/PaymentCompleted';
import OrderCompletedModal from './components/Modal/OrderCompletedModal';
import AppLabel from './components/Label/AppLabel';

function App() {

  return (
    <>
      {/* Modal sau khi order xong */}
      <OrderCompletedModal />
      {/* Cho biết đây là web dev hay production */}
      <AppLabel/>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          {/* <Route exact path='/payment/completed' element={<PaymentCompleted />}></Route> */}
          <Route path='*' element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
