import {useState} from 'react';
import { useSelector} from 'react-redux';
import { getFormattedPrice, getTotalPriceInCart } from '../../functions';
import CartModal from './CartModal';

export default function CartCheckoutBox() {
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    const productsInCart = useSelector(state => state.productsInCart);

    return (
        <>
            {productsInCart.length > 0 && <button
                onClick={() => setShowCheckoutModal(true)}
                type="button"
                className="fixed bottom-10 bg-red-600 hover:opacity-95 w-[90%] left-[5%] h-[50px] rounded-[15px] shadow-lg animate-[bounce_2s_ease-in-out_infinite] text-white flex items-center shadow-lg shadow-red-500 px-4 z-10"
            >
                <i className="fa-solid fa-cart-shopping relative">
                    <span className="absolute flex items-center justify-center top-[-10px] right-[-10px] h-4 w-4 text-[10px] bg-white text-black rounded-full">
                        {productsInCart.length}
                    </span>
                </i>
                <span className="ml-5 text-[16px] xs:text-[24px] font-bold">
                    {getFormattedPrice(getTotalPriceInCart(productsInCart))}
                </span>
                <span className="uppercase ml-auto font-bold">
                    Checkout <i className="fa-solid fa-caret-right"></i>
                </span>
            </button>}
            <CartModal show={showCheckoutModal} handleCloseModal={() => setShowCheckoutModal(false)} />
        </>
    );
}
