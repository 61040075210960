import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import ProductSearchCard from "../Product/ProductSearchCard";
import { changeProductsOrdering } from '../../reducers/Product/ProductsOrderingSlice'

export default function SearchProduct() {
    const searchResults = useRef(null);
    const dispatch = useDispatch();
    const [showSearchResults, setShowSearchResults] = useState(true);
    const [search, setSearch] = useState("");
    const [productsSearchResults, setProductsSearchResults] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const productCategories = useSelector(
        (state) => state.categoriesWithProducts
    );
    const appInfo = useSelector((state) => state.appInfo);

    useEffect(() => {
        if (productCategories) {
            const allProducts = productCategories
                .map((category) => category.products)
                .flat();
            setAllProducts(allProducts);
        }
    }, [productsSearchResults]);

    useEffect(() => {
        if (search !== "") {
            const results = allProducts.filter((product) =>
                product.name.toLowerCase().includes(search.toLowerCase())
            );
            setProductsSearchResults(results);
        } else {
            setProductsSearchResults([]);
        }
    }, [search]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchResults.current && !searchResults.current.contains(event.target)) {
                setShowSearchResults(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleProductSearchCardOnClick = ({ materialId, name, image, unitPrice }) => {
        image = image ? image : `/images/${appInfo.storeId}/brand-logo.webp`;
        const product = { materialId, name, image, unitPrice};
        dispatch(changeProductsOrdering(product));
        setShowSearchResults(false)
    }

    return (
        <div ref={searchResults} className="relative w-full lg:w-1/2">
            <input
                autoFocus
                className="border-2 w-full px-2 py-1 ml-1"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onFocus={() => setShowSearchResults(true)}
            ></input>
            <i className='text-sm fa-solid fa-search absolute right-2 top-[50%] -translate-y-1/2'></i>
            {!!showSearchResults && <div className="absolute top-[100%] w-full bg-white max-h-[calc((120px+16px+2px)*3)] z-20 shadow-2xl overflow-y-auto">
                {productsSearchResults.map((product) => (
                    <ProductSearchCard
                        key={product.materialId}
                        name={product.name}
                        image={product.image}
                        unitPrice={product.unitPrice}
                        onClick={() => { handleProductSearchCardOnClick(product) }}
                    />
                ))}
            </div>}
        </div>
    )
}
