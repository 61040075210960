import { useState } from "react";
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from "react-redux";
import CartProductBlock from "./CartProductBlock";
import {
  clearCart
} from "../../reducers/Product/ProductsInCartSlice";
import { initOrderCompletedInfo } from "../../reducers/Order/OrderCompletedInfoSlice";
import PaymentOption from "../Payment/PaymentOption";
import { getFormattedPrice, getTotalPriceInCart } from "../../functions";
import Axios from "../../functions/axios";
import CommonModal from "../Modal/CommonModal";
import { useEffect } from "react";
import LoadingFullScreen from "../Loading/LoadingFullScreen";

export default function CheckoutModal({ show, handleCloseModal = () => { } }) {
  const [cookies, setCookie] = useCookies(['productsHistory']);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const productsInCart = useSelector((state) => state.productsInCart);
  const appInfo = useSelector((state) => state.appInfo);
  const [paymentMethod, setPaymentMethod] = useState(0);
  // Momo: 2

  const validateCart = () => {
    let validateQuantity = true;
    productsInCart.forEach((product) => {
      if (product.quantity <= 0) {
        validateQuantity = false;
      };
    });
    if (!validateQuantity) {
      alert("Vui lòng nhập số lượng hợp lệ");
      return false;
    };
    return true;
  };

  const handlePlaceOrder = () => {
    if (!validateCart()) return;
    setLoading(true);
    const orderDetails = productsInCart.map((product) => ({
      MaterialId: product.materialId,
      Quantity: product.quantity,
      Description: product.description,
    }));
    const orders = {
      AppId: parseInt(appInfo.appId),
      StoreId: parseInt(appInfo.storeId),
      TableStr: appInfo.tableStr,
      UId: '',
      OrderDetails: orderDetails,
      PaymentType: paymentMethod,
    };
    Axios.post("/api/voucher/CreateVoucherOrderWeb", orders)
      .then((res) => {
        const orderId = res.data.data?.id;
        handleCloseModal();
        dispatch(clearCart());
        setCookie('productsHistory', { orderId: res.data.data.id, appInfo: appInfo }, { path: '/', maxAge: 60 * 60 * 3 });
        // if (false && res.data?.data?.id && paymentMethod === 2) {
        //   const orderId = res.data.data.id;
        //   const params = {
        //     orderId: orderId,
        //     repay: false,
        //     appId: parseInt(appInfo.appId), 
        //     redirectUrl: window.location.origin +
        //       `/payment/completed?paymentMethod=${paymentMethod}`,
        //   };
        //   const stringParams = new URLSearchParams(params).toString();
        //   Axios.post(`/api/ProductOnline/PayByMomo?${stringParams}`)
        //     .then((response) => {
        //       alert("Đặt hàng thành công, chuẩn bị sẵn sàng thanh toán");
        //       console.log(response);
        //       const url = response.data.data?.res;
        //       if (!url) return alert("Có lỗi xảy ra vui lòng quay lại sau");
        //       window.location.href = url;
        //     })
        //     .catch(() => {
        //       alert("Có lỗi xảy ra vui lòng quay lại sau");
        //     })
        //     .finally(() => setLoading(false))
        // } else {
        setLoading(false);
        dispatch(initOrderCompletedInfo({ show: true, status: 'success', orderId: orderId, buttonText: 'Xem lại đơn hàng', buttonFunction: () => window.location.href = '/?showProductHistoryModal=true' }));
        // }
      })
      .catch(() => {
        handleCloseModal();
        dispatch(initOrderCompletedInfo({ show: true, status: 'fail', orderId: null, buttonText: 'Thử lại', buttonFunction: () => { } }));
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingFullScreen loading={loading} />
      <CommonModal id="checkout-modal" show={show} onClose={handleCloseModal}>
        <div
          className="max-h-[95vh] w-[90vw] md:max-h-[90vh] 2xl:w-[60vw] bg-white rounded-[10px]"
        >
          <div className="mx-5 h-[60px] flex justify-between items-center text-[25px] border-b font-bold text-gray-900">
            <span className="flex items-center">
              Shopping Cart ({productsInCart.length})
            </span>
            <button onClick={() => dispatch(clearCart())} className="w-7 h-7 ml-auto bg-red-600 rounded-md flex items-center justify-center mr-4">
              <i class="fa-solid fa-broom text-[12px] text-white"></i>
            </button>
            <button
              type="button"
              aria-label="close-cart-modal"
              className="fa-solid fa-xmark hover:text-gray-600"
              onClick={handleCloseModal}
            ></button>

          </div>
          <div className="flex flex-wrap md:py-5">
            <div
              className="max-h-[260px] md:max-h-[calc(60vh-60px)] w-full md:w-[60%] md:px-5 overflow-y-auto"
            >
              {productsInCart && productsInCart.length > 0 ? (
                productsInCart.map((product, index) => (
                  <CartProductBlock
                    key={product.materialId}
                    {...product}
                  />
                ))
              ) : (
                <div className="flex items-center justify-center h-full text-gray-500 px-5">
                  <div>
                    <svg
                      viewBox="0 0 752 752"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-32 w-32 fill-current mx-auto"
                    >
                      <g>
                        <path d="m378.37 418.62c-24.625 0-47.359-9.4727-64.406-26.992-2.8398-2.8398-2.8398-7.1055 0-9.9453s7.1055-2.8398 9.9453 0c14.203 14.68 33.621 22.73 54.461 22.73 26.992 0 52.094-14.207 66.301-37.887 4.7344-7.5781 7.5781-14.207 9.4727-23.207 0.47266-3.7891 4.2617-6.6289 8.0508-5.6836 3.7891 0.94531 6.6289 4.2617 5.6836 8.0508-1.8945 10.891-6.1562 18.941-11.367 27.941-16.102 27.469-46.414 44.992-78.141 44.992z" />
                        <path d="m358.95 331.96c-3.7891 0-7.1055-3.3164-7.1055-7.1055v-37.887c0-3.7891 3.3164-7.1055 7.1055-7.1055s7.1055 3.3164 7.1055 7.1055v37.887c-0.003906 3.793-3.3164 7.1055-7.1055 7.1055z" />
                        <path d="m407.26 331.96c-3.7891 0-7.1055-3.3164-7.1055-7.1055v-37.887c0-3.7891 3.3164-7.1055 7.1055-7.1055 3.7891 0 7.1055 3.3164 7.1055 7.1055v37.887c0 3.793-3.3164 7.1055-7.1055 7.1055z" />
                        <path d="m513.81 300.23c-3.7891-0.47266-7.5781 1.8945-8.0508 5.6836l-23.203 141.13h-199.85l-25.102-186.59c-2.8398-16.102-14.68-26.52-30.309-26.52h-35.52c-3.7891 0-7.1055 3.3164-7.1055 7.1055 0 3.7891 3.3164 7.1055 7.1055 7.1055h35.52c8.5234 0 14.68 5.6836 16.102 14.68l34.098 257.16c-14.207 4.7344-24.152 17.996-24.152 33.625 0 19.418 16.102 35.52 35.52 35.52s35.52-16.102 35.52-35.52c0-8.0508-2.8398-15.156-7.1055-21.312h103.71c-4.2617 6.1562-7.1055 13.262-7.1055 21.312 0 19.418 16.102 35.52 35.52 35.52s35.52-16.102 35.52-35.52c0-19.426-15.629-35.527-35.52-35.527h-157.23l-7.5781-56.828h204.59c3.3164 0 6.6289-2.3672 7.1055-6.1562l23.68-146.81c0.47656-3.7891-2.3672-7.1055-6.1562-8.0547zm-203.16 253.37c0 11.84-9.4727 21.312-21.312 21.312-11.84 0-21.312-9.4727-21.312-21.312s9.4727-21.312 21.312-21.312c11.84 0 21.312 9.4727 21.312 21.312zm160.07 0c0 11.84-9.4727 21.312-21.312 21.312s-21.312-9.4727-21.312-21.312 9.4727-21.312 21.312-21.312 21.312 9.4727 21.312 21.312z" />
                      </g>
                    </svg>
                    <div className="text-center">
                      Giỏ hàng đang trống bạn vui lòng chọn thêm món nghen!
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-[40%] p-4 leading-[32px]">

              <div className="text-[25px] font-bold text-red-600 flex justify-between py-2">
                <span>Tổng thanh toán:{" "}</span>
                <span>{getFormattedPrice(getTotalPriceInCart(productsInCart))}</span>
              </div>
              {/* <div>
                {appInfo.appId === '2' &&
                  <div>
                    <div>Phương Thức Thanh Toán</div>
                    <div className="border-2 mb-2 rounded-[4px]">
                      <PaymentOption
                        onClick={() => setPaymentMethod(2)}
                        title={"Thanh toán qua ví điện tử MoMo"}
                        value={2}
                        currentValue={paymentMethod}
                        imgSrc={"/images/common/momo.png"}
                      />
                    </div>
                  </div>}
              </div> */}
              <button
                type="button"
                aria-label="place-order"
                onClick={() => handlePlaceOrder()}
                className={`w-full mt-2 bg-red-600 text-white rounded-[4px] text-[22px] font-bold py-2 ${productsInCart.length > 0
                  ? "hover:opacity-80"
                  : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={productsInCart.length === 0}
              >
                Order Ngay
              </button>

            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
}
