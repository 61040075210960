import React from 'react'
import { getFormattedPrice } from '../../functions';
import { useSelector } from 'react-redux';

export default function ProductHistoryCard({ image, name, unitPrice, quantity, description }) {
    const appInfo = useSelector((state) => state.appInfo);
    image = image ? image : `/images/${appInfo.storeId}/brand-logo.webp`;
    return (
        <div className='flex p-2 border-y hover:bg-gray-100 items-center'>
            <div
                className="w-[120px] h-[120px] bg-cover bg-center rounded-l-[4px] border-2"
                style={{ backgroundImage: `url('${image}')` }}
            ></div>
            <div className="w-[calc(100%-120px)] px-4 font-bold">
                <div className="pt-2">
                    {name} <span className='text-sm font-normal h-[20px]'>{description}</span>
                </div>
                <div className="py-1">{getFormattedPrice(unitPrice)}</div>
                <div>Số lượng: {quantity}</div>
            </div>
        </div>
    )
}
