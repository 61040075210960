
export const getFormattedPrice = (price = 0, currency = "VND") => {
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
    });
    const formattedPrice = formatter.format(price);
    return formattedPrice;
};

export const getTotalPriceInCart = (productsInCart) => {
    let totalPrice = 0;
    productsInCart.forEach((product) => {
        totalPrice += product.unitPrice * product.quantity;
    });
    return totalPrice;
};

export const getProductsFromLocalStorage = () => {
    const defaultProductsInCart = JSON.parse(localStorage.getItem("productsInCart"));
    let productsInCart = Array.isArray(defaultProductsInCart) ? [...defaultProductsInCart] : [];
    productsInCart = productsInCart.filter(product => product.quantity > 0);
    // Kiểm tra localStorage nếu có đối tượng bị sửa quantity <= 0 thì xóa đi
    if (defaultProductsInCart && JSON.stringify(productsInCart) !== JSON.stringify(defaultProductsInCart)) {
        localStorage.setItem("productsInCart", JSON.stringify(productsInCart));
    }

    return productsInCart;
};

// Gom các sản phẩm chung nhóm lại thành 1 sản phẩm
export const handleProductsData = (products) => {
    let groupID = []; // Mảng chứa các group ID của các sản phẩm
    let handledProducts = []; // Mảng chứa các sản phẩm đã được gom lại 
    // Bước 1: Gom tất cả các group ID thành một mảng
    products.forEach((product) => groupID.push(product.groupMaterialId));
    // Bước 2: Lọc các group ID trùng nhau
    groupID = [...new Set(groupID)];
    // Bước 3: Gom các sản phẩm chung groupID lại thành 1 mảng sản phẩm
    products.forEach((product) => {
        const groupIDIndex = groupID.findIndex((id) => id === product.groupMaterialId);
        if (Array.isArray(handledProducts[groupIDIndex])) {
            handledProducts[groupIDIndex].push(product);
        } else {
            handledProducts[groupIDIndex] = [product];
        }
    });
    console.log(handledProducts)
    return handledProducts;
};