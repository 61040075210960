import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Footer() {
    const appInfo = useSelector((state) => state.appInfo);
    return (
        <footer className="bg-gray-900 text-center lg:text-left">
            <div className="text-gray-100 text-center p-4">
                <div>© 2022 Copyright</div>
                <Link
                    to="/"
                    className="text-gray-800 font-bold flex items-center justify-center text-2xl"
                >
                    {/* <img src={`/images/2/brand-logo.webp`} alt='logo' className='h-[50px] mr-1 rounded-full'></img> */}
                    <img src={`/images/common/app-logo.png`} alt='logo' className='h-[50px] ml-1'></img>
                </Link>
                <div className="flex items-center justify-center">
                    Powered by BAGang Tech
                </div>
            </div>
        </footer>
    );
}
