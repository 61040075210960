import { createSlice } from '@reduxjs/toolkit'

const initialState = [];

export const productsGroupOrderingSlice = createSlice({
  name: 'ProductsGroupOrdering',
  initialState,
  reducers: {
    changeProductsGroupOrdering: (state, action) => {
      return [...action.payload];
    },
    removeProductsGroupOrdering: () => {
      return initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeProductsGroupOrdering, removeProductsGroupOrdering} = productsGroupOrderingSlice.actions

export default productsGroupOrderingSlice.reducer