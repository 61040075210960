import { useState, useEffect } from "react";
import ProductBlock from "../components/Product/ProductBlock";
import OrderLayout from "../layouts/OrderLayout";
import {
  getFormattedPrice,
  getProductsFromLocalStorage,
  handleProductsData,
} from "../functions";
import { useSelector, useDispatch } from "react-redux";
import { initCategoriesWithProducts } from "../reducers/Product/CategoriesWithProductsSlice";
import { changeProductsOrdering } from "../reducers/Product/ProductsOrderingSlice";
import { initProductsInCart } from "../reducers/Product/ProductsInCartSlice";
import { addProductHistory } from "../reducers/Product/ProductHistorySlice";
import CartCheckoutBox from "../components/Cart/CartCheckoutBox";
import Axios from "../functions/axios";
import { initAppInfo } from "../reducers/App/AppInfoSlice";
import PageNotFound from "./404";
import { useCookies } from "react-cookie";
import Loading from "../components/Loading/Loading";
import ProductOrderingBox from "../components/Product/ProductOrderingBox";
import DinningTable from "../components/CustomIcon/DinningTable";
import CategoryBlock from "../components/Category/CategoryBlock";
import { changeProductsGroupOrdering } from "../reducers/Product/ProductsGroupOrderingSlice";

export default function Home() {
  const [cookiesProductsHistory] = useCookies(["productsHistory"]);
  const [cookiesAppInfo, setCookiesAppInfo] = useCookies(["appInfo"]);
  const categoriesWithProducts = useSelector(
    (state) => state.categoriesWithProducts
  );
  const dispatch = useDispatch();
  const appInfo = useSelector((state) => state.appInfo);
  const [isValidAppInfo, setIsValidAppInfo] = useState(null); //null: chưa kiêm tra, false: đã kiểm tra và phát hiện sai, true: đã kiểm tra và phát hiện đúng
  const [loading, setLoading] = useState(false);
  const [grossAmount, setGrossAmount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [layoutListCategory, setLayoutListCategory] =
    useState("horizontal-list");

  //Lấy thông tin lịch sử đơn hàng dựa trên cookie, khi khách order sẽ lưu orderId vào cookie.
  useEffect(() => {
    if (!isValidAppInfo) return;
    const cookieHistoryOrderId =
      cookiesProductsHistory.productsHistory?.orderId;
    const cookieHistoryAppInfo =
      cookiesProductsHistory.productsHistory?.appInfo;
    // Lấy productHistory trong cookie nếu appInfo trong cookie trùng với appInfo hiện tại
    // So sánh appInfo, nhằm giải quyết vấn đề khách chuyển bàn, lịch sử order cũ dính vào bàn mới
    if (
      cookieHistoryOrderId &&
      JSON.stringify(cookieHistoryAppInfo) === JSON.stringify(appInfo)
    ) {
      Axios.get(
        `/api/Shift/GetDetailOrder?voucherOrderId=${cookieHistoryOrderId}`
      )
        .then((res) => {
          const productsHistory = res.data.data?.detailForPOS;
          setGrossAmount(res.data.data?.grossAmount);
          if (productsHistory) dispatch(addProductHistory(productsHistory));
        })
        .catch((err) => console.log(err));
    } else if (cookieHistoryOrderId) {
      // xóa productHistory trong cookie nếu appInfo trong cookie không trùng với appInfo hiện tại
      setCookiesAppInfo("productsHistory", null, { maxAge: 0, path: "/" });
    }
  }, [isValidAppInfo]);

  // Lấy thông tin appInfo từ query params, sau đó gán cho cookie rồi reload lại trang
  // Lúc này mới là lúc lấy appInfo cho trang web, bước đầu chỉ lấy rồi gán cho cookie chứ không xài.
  // Mục đích là để ẩn query params đi, tránh hiện lên trên thanh địa chỉ của trình duyệt
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const appId = queryParams.get("appId");
    const tableStr = queryParams.get("tableStr");
    const storeId = queryParams.get("storeId");
    if (tableStr && storeId && appId) {
      setCookiesAppInfo(
        "appInfo",
        { appId, tableStr, storeId },
        { maxAge: 60 * 60 * 12, path: "/" }
      ); //set cookie appInfo 12 tiếng
      dispatch(initAppInfo({ appId, tableStr, storeId }));
      window.location.href = "/";
    } else {
      if (
        cookiesAppInfo.appInfo?.appId &&
        cookiesAppInfo.appInfo?.tableStr &&
        cookiesAppInfo.appInfo?.storeId
      ) {
        setCookiesAppInfo("appInfo", cookiesAppInfo.appInfo, {
          maxAge: 60 * 60 * 12,
          path: "/",
        });
        dispatch(initAppInfo(cookiesAppInfo.appInfo));
        setIsValidAppInfo(true);
      } else {
        setIsValidAppInfo(false);
      }
    }
  }, []);

  useEffect(() => {
    // Nếu chưa có app info chưa hợp lệ thì không gọi api
    if (!isValidAppInfo) return;
    setLoading(true);
    Axios.get(
      `/api/ProductOnline/GetCategoriesWithProductsV2?storeId=${appInfo.storeId}&appId=${appInfo.appId}`
    )
      .then((result) => {
        //Init store danh mục và danh sách sản phẩm lấy từ API
        dispatch(initCategoriesWithProducts(result.data));
        // Nếu có danh mục thì chọn danh mục đầu tiên
        if (Array.isArray(result.data) && result.data.length > 0)
          setSelectedCategory(result.data[0].categoryId);
        // Init giỏ hàng từ localStorage
        dispatch(
          initProductsInCart({
            products: getProductsFromLocalStorage(),
            appInfo: appInfo,
          })
        );
      })
      .catch((err) => setIsValidAppInfo(false))
      .finally(() => setLoading(false));
  }, [isValidAppInfo]);

  // Nếu client chưa kiểm tra xong appInfo thì không hiển thị gì cả
  if (isValidAppInfo === null) return <></>;
  // Nếu đã có rồi nhưng không hợp lệ thì hiển thị trang 404
  if (isValidAppInfo === false) return <PageNotFound />;

  if (loading)
    return (
      <OrderLayout>
        <Loading loading={true} />
      </OrderLayout>
    );

  return (
    <OrderLayout>
      <div>
        {layoutListCategory === "horizontal-list" ? 
        <div className="flex justify-end mx-5 my-2">
          <button class="fa-solid fa-table-cells-large p-2 bg-red-600 text-white rounded-md" onClick={() => setLayoutListCategory('menu')}></button>
        </div>
        : 
        <div className="flex justify-end mx-5 my-2">
          <button class="fa-regular fa-square-minus p-2 bg-red-600 text-white rounded-md" onClick={() => setLayoutListCategory('horizontal-list')}></button>
        </div>}
        {layoutListCategory === "horizontal-list" ? (
          <div
            id="navigation-order-container"
            className="flex w-full max-w-full overflow-auto animate__animated animate__fadeInLeftBig animate__faster z-10"
          >
            {categoriesWithProducts.map((categoryWithProduct, index) => (
              <CategoryBlock
                key={categoryWithProduct.categoryId}
                name={categoryWithProduct.name}
                selected={selectedCategory === categoryWithProduct.categoryId}
                src={categoryWithProduct.src}
                onClick={() =>
                  setSelectedCategory(categoryWithProduct.categoryId)
                }
              />
            ))}
          </div>
        ) : (
          <div
            id="navigation-order-container"
            className="flex flex-wrap w-full max-w-full overflow-auto animate__animated animate__slideInRight animate__faster bg-white"
          >
            {categoriesWithProducts.map((categoryWithProduct, index) => (
              <CategoryBlock
                key={categoryWithProduct.categoryId}
                name={categoryWithProduct.name}
                selected={selectedCategory === categoryWithProduct.categoryId}
                src={categoryWithProduct.src}
                onClick={() =>
                  setSelectedCategory(categoryWithProduct.categoryId)
                }
              />
            ))}
          </div>
        )}
        <div className="mt-5 mx-5 px-4 py-2 font-bold text-[1rem] text-white bg-orange-600 rounded-lg text-left">
          <div className="flex">
            <div className="w-12 flex items-center">
              <DinningTable />
            </div>
            Bàn {appInfo.tableStr}
          </div>
          {cookiesProductsHistory.productsHistory?.orderId && (
            <>
              <div className="flex">
                <div className="w-12 flex items-center">
                  <i class="fa-solid fa-sack-dollar"></i>
                </div>
                {getFormattedPrice(grossAmount)}
              </div>
              <div className="flex">
                <div className="w-12 flex items-center">
                  <i className="fa-solid fa-wallet"></i>
                </div>
                Order #{cookiesProductsHistory.productsHistory?.orderId}
              </div>
            </>
          )}
        </div>
        {categoriesWithProducts.map((categoryWithProduct, index) => {
          if (categoryWithProduct.categoryId !== selectedCategory) return null;
          return (
            <div
              id="product-container"
              className="px-5"
              key={categoryWithProduct.categoryId}
            >
              <div className="w-full pt-4 mb-4 font-bold text-[25px] lg:text-[35px] flex items-center border-b-2 border-gray-800">
                <h1 className="text-gray-800">{categoryWithProduct.name}</h1>
              </div>
              <div
                id={`product-container-${index}`}
                className="w-full flex flex-wrap"
              >
                {handleProductsData(categoryWithProduct.products).map(
                  (productsGroup) => (
                    <div
                      key={productsGroup[0].groupMaterialId}
                      className="w-full xs:w-1/3 md:w-1/3 lg:w-1/4 2xl:w-1/5 xs:px-1 md:p-5 pb-5 rounded-[5px]"
                    >
                      <ProductBlock
                        productsGroup={productsGroup}
                        handleOrderProduct={() => {
                          dispatch(changeProductsGroupOrdering(productsGroup));
                          dispatch(changeProductsOrdering(productsGroup[0]));
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ProductOrderingBox />
      <CartCheckoutBox />
    </OrderLayout>
  );
}
