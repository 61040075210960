import React from 'react'
import CommonModal from './CommonModal'
import ProductHistoryList from '../Product/ProductHistoryList'

export default function ProductsHistoryModal({show, onClose, productHistory = []}) {
    return (
        <CommonModal id="product-history-modal" show={show} onClose={onClose}>
            <div className="w-[90vw] bg-white rounded-lg shadow-lg">
                <div className="mx-5 h-[60px] flex justify-between items-center text-[25px] border-b font-bold text-gray-900">
                    <span>Lịch sử đặt hàng ({productHistory.length})</span>
                    <button
                        type="button"
                        aria-label="close-cart-modal"
                        className="fa-solid fa-xmark hover:text-gray-600"
                        onClick={onClose}
                    ></button>
                </div>
                {productHistory.length === 0 ?
                    <div className="flex items-center justify-center h-full text-gray-500 p-5">
                        <div>
                            <svg
                                viewBox="0 0 752 752"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-32 w-32 fill-current mx-auto"
                            >
                                <g>
                                    <path d="m378.37 418.62c-24.625 0-47.359-9.4727-64.406-26.992-2.8398-2.8398-2.8398-7.1055 0-9.9453s7.1055-2.8398 9.9453 0c14.203 14.68 33.621 22.73 54.461 22.73 26.992 0 52.094-14.207 66.301-37.887 4.7344-7.5781 7.5781-14.207 9.4727-23.207 0.47266-3.7891 4.2617-6.6289 8.0508-5.6836 3.7891 0.94531 6.6289 4.2617 5.6836 8.0508-1.8945 10.891-6.1562 18.941-11.367 27.941-16.102 27.469-46.414 44.992-78.141 44.992z" />
                                    <path d="m358.95 331.96c-3.7891 0-7.1055-3.3164-7.1055-7.1055v-37.887c0-3.7891 3.3164-7.1055 7.1055-7.1055s7.1055 3.3164 7.1055 7.1055v37.887c-0.003906 3.793-3.3164 7.1055-7.1055 7.1055z" />
                                    <path d="m407.26 331.96c-3.7891 0-7.1055-3.3164-7.1055-7.1055v-37.887c0-3.7891 3.3164-7.1055 7.1055-7.1055 3.7891 0 7.1055 3.3164 7.1055 7.1055v37.887c0 3.793-3.3164 7.1055-7.1055 7.1055z" />
                                    <path d="m513.81 300.23c-3.7891-0.47266-7.5781 1.8945-8.0508 5.6836l-23.203 141.13h-199.85l-25.102-186.59c-2.8398-16.102-14.68-26.52-30.309-26.52h-35.52c-3.7891 0-7.1055 3.3164-7.1055 7.1055 0 3.7891 3.3164 7.1055 7.1055 7.1055h35.52c8.5234 0 14.68 5.6836 16.102 14.68l34.098 257.16c-14.207 4.7344-24.152 17.996-24.152 33.625 0 19.418 16.102 35.52 35.52 35.52s35.52-16.102 35.52-35.52c0-8.0508-2.8398-15.156-7.1055-21.312h103.71c-4.2617 6.1562-7.1055 13.262-7.1055 21.312 0 19.418 16.102 35.52 35.52 35.52s35.52-16.102 35.52-35.52c0-19.426-15.629-35.527-35.52-35.527h-157.23l-7.5781-56.828h204.59c3.3164 0 6.6289-2.3672 7.1055-6.1562l23.68-146.81c0.47656-3.7891-2.3672-7.1055-6.1562-8.0547zm-203.16 253.37c0 11.84-9.4727 21.312-21.312 21.312-11.84 0-21.312-9.4727-21.312-21.312s9.4727-21.312 21.312-21.312c11.84 0 21.312 9.4727 21.312 21.312zm160.07 0c0 11.84-9.4727 21.312-21.312 21.312s-21.312-9.4727-21.312-21.312 9.4727-21.312 21.312-21.312 21.312 9.4727 21.312 21.312z" />
                                </g>
                            </svg>
                            <div className="text-center">
                                Có vẻ bạn chưa đặt món nào, đặt ngay thức uống, món ăn yêu thích của bạn cho đỡ trống nghen ^^!
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <ProductHistoryList productHistory={productHistory} />
                        {/* <div className="text-center py-5">
                <button className="py-2 px-5 bg-red-600 text-white" onClick={handlePaymentMoMo}>Thanh Toán MoMo</button>
              </div>
              {JSON.stringify(productHistory)} */}
                    </div>
                }
            </div>
        </CommonModal>
    )
}
