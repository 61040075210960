import React from 'react'
import { getFormattedPrice } from '../../functions'
import { useSelector } from 'react-redux'

export default function ProductSearchCard({image, name, unitPrice, onClick}) {
    const appInfo = useSelector((state) => state.appInfo);
    image = image? image: `/images/${appInfo.storeId}/brand-logo.webp`;
    return (
        <div className='flex p-2 border-y hover:bg-gray-100' onClick={onClick}>
            <div
                className="w-[120px] h-[120px] bg-cover bg-center rounded-l-[4px]"
                style={{ backgroundImage: `url('${image}')` }}
            ></div>
            <div className="w-[calc(100%-120px)] px-4">
                <div className="pt-2">{name}</div>
                <div className="py-2">{getFormattedPrice(unitPrice)}</div>
            </div>
        </div>
    )
}
