import { useSelector, useDispatch} from 'react-redux';
import CommonModal from './CommonModal'
import {hideOrderCompletedInfoModal} from '../../reducers/Order/OrderCompletedInfoSlice'

export default function OrderCompletedModal() {
    const dispatch = useDispatch();
    const orderCompletedInfo = useSelector(state => state.orderCompletedInfo);
    const {show, status, orderId, buttonText, buttonFunction} = orderCompletedInfo;


    const getTextClassColor = (status) => {
        switch (status) {
            case "success":
                return "text-green-600";
            case "fail":
                return "text-red-600";
            case "pending":
                return "text-yellow-600";
            default:
                return "text-gray-600";
        };
    };

    const getBackgroundColor = (status) => {
        switch (status) {
            case "success":
                return "bg-green-600";
            case "fail":
                return "bg-red-600";
            case "pending":
                return "bg-yellow-600";
            default:
                return "bg-gray-600";
        };
    };


    const getClassIcon = (status) => {
        switch (status) {
            case "success":
                return 'fa-solid fa-check';
            case "fail":
                return 'fa-solid fa-xmark';
            case "pending":
                return 'fa-solid fa-clock';
            default:
                return 'fa-solid fa-xmark';
        };
    };

    const getMessage = (status) => {
        switch (status) {
            case "success":
                return "Đơn hàng đã được tạo, bạn vui lòng đến quầy để thanh toán nhé!";
            case "fail":
                return "Đặt hàng thất bại :(, vui lòng thử lại sau";
            case "pending":
                return "Đang xử lý đơn hàng của bạn, vui lòng đợi trong giây lát";
            default:
                return "Đặt hàng thất bại, có lỗi không xác định xảy ra";
        };
    };

    const handleCloseOrderCompletedModal = () => {
        dispatch(hideOrderCompletedInfoModal());
        buttonFunction();
    };

    return (
        <CommonModal id='order-success-modal' show={show} onClose={handleCloseOrderCompletedModal}>
            <div className='bg-white w-[80vw] md:w-[600px] h-min flex items-center justify-center py-8 rounded-lg'>
                <div>
                    <div className={`h-24 w-24 rounded-full ${getBackgroundColor(status)} flex items-center justify-center mx-auto`}>
                        <i className={`${getClassIcon(status)} text-white text-[45px]`}></i>
                    </div>
                    <div className="m-3 text-center text-xl leading-[40px] text-gray-600">
                        <p className={`${getTextClassColor(status)} font-semibold text-md`}>
                            {getMessage(status)}
                        </p>
                        <div>
                            Mã số đơn hàng của bạn là{" "}
                            <b className={`${getTextClassColor(status)}`}>{orderId}</b>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button onClick={handleCloseOrderCompletedModal}>
                            <div className="text-center">
                                <button className={`bg-gray-600 text-white text-[15px] text-center h-12 w-52 font-medium hover:opacity-80`}>
                                   {buttonText}
                                </button>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </CommonModal>
    )
}
