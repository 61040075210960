import { createSlice } from '@reduxjs/toolkit'

const initialState = { show: false, status: '', orderId: '', buttonText: '', buttonFunction: null};

export const orderCompletedInfoSlice = createSlice({
    name: 'orderCompletedInfo',
    initialState,
    reducers: {
        initOrderCompletedInfo(state, action) {
            return { ...state, ...action.payload };
        },

        hideOrderCompletedInfoModal(state, action) {
            return initialState;
        },
    },
})

// Action creators are generated for each case reducer function
export const { initOrderCompletedInfo, hideOrderCompletedInfoModal} = orderCompletedInfoSlice.actions

export default orderCompletedInfoSlice.reducer