import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  materialId: null,
  name: "",
  image: "",
  unitPrice: 0,
  quantity: 0,
  description: "",
};

export const productsOrderingSlice = createSlice({
  name: 'productsOrdering',
  initialState,
  reducers: {
    changeProductsOrdering: (state, action) => {
      return {...action.payload, quantity: 1, description: ""};
    },
    increaseQuantityOrdering: (state) => {
      state.quantity += 1;
    },
    decreaseQuantityOrdering: (state) => {
      state.quantity -= 1;
    },
    changeQuantityOrdering: (state, action) => {
      if (action.payload >= 0) state.quantity = action.payload; else state.quantity = 0;
    },
    removeProductsOrdering: () => {
      return initialState;
    },
    changeDecriptionOrdering: (state, action) => {
      state.description = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeProductsOrdering, increaseQuantityOrdering, decreaseQuantityOrdering, changeQuantityOrdering, removeProductsOrdering, changeDecriptionOrdering } = productsOrderingSlice.actions

export default productsOrderingSlice.reducer