export default function LoadingFullScreen({loading}) {
    if (!loading) return;
    return (
      <div className="w-full h-screen fixed top-0 left-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <img
          alt="loading"
          className="rounded-full animate-[spin_2s_ease_infinite] w-16 h-16"
          src="/images/common/logo.webp"
        ></img>
      </div>
    );
  }