import React, { useEffect } from "react";
import { getFormattedPrice } from "../../functions";

import { useSelector, useDispatch } from "react-redux";

import { decreaseQuantityInCart, increaseQuantityInCart, removeProductsInCart, changeQuantityInCart } from "../../reducers/Product/ProductsInCartSlice";

export default function CartProductBlock({
  materialId = "",
  image = "",
  name = "",
  unitPrice = 0,
  quantity = 0,
  description = "",
}) {
  const dispatch = useDispatch();
  const appInfo = useSelector((state) => state.appInfo);
  
  image = image ? image : `/images/${appInfo.storeId}/brand-logo.webp`;

  const handleDecreaseQuantity = () => {
    dispatch(decreaseQuantityInCart(materialId));
  };

  const handleQuantityInputOnChange = (e) => {
    const value = e.target.value;
    dispatch(changeQuantityInCart({ materialId, quantity: value }));
  };

  const handleIncreaseQuantity = () => {
    dispatch(increaseQuantityInCart(materialId));
  };

  const handleRemoveProduct = () => {
    dispatch(removeProductsInCart(materialId));
  };

  return (
    <div
      className={`flex items-center shadow shadow-red-300 text-gray-900 border rounded-[4px] relative m-2`}
    >
      <div
        className="m-2 w-[100px] h-[100px] md:w-[120px] md:h-[120px] bg-cover bg-center rounded"
        style={{ backgroundImage: `url('${image}')` }}
      ></div>
      <div className="w-[calc(100%-120px)] px-4">
        <div className="pt-1 font-bold">{name}</div>
        <div className="text-red-600 font-bold text-[22px]">{getFormattedPrice(unitPrice)}</div>
        <div className="text-sm">
          Ghi chú: {description}
        </div>
        <div className="flex justify-end pb-1">
          <button
            onClick={handleDecreaseQuantity}
            className="border text-gray-600 h-8 w-8 flex items-center justify-center hover:opacity-60"
          >
            <i className="text-[12px] fa-solid fa-minus"></i>
          </button>
          <input
            className="w-12 h-8 text-center text-black hide-input-number-arrow text-lg font-bold border"
            type="number"
            value={quantity}
            onChange={handleQuantityInputOnChange}
          ></input>
          <button
            onClick={handleIncreaseQuantity}
            className="border text-gray-600 h-8 w-8 flex items-center justify-center hover:opacity-60"
          >
            <i className="text-[12px] fa-solid fa-plus"></i>
          </button>
        </div>
        <button className="fa-solid fa-trash absolute top-2 right-2 text-red-400 hover:opacity-80" onClick={handleRemoveProduct}></button>
      </div>
    </div>
  );
}
