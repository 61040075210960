import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    table: '',
    name: '',
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    init: (state, action) => {
        return {
            table: (action.payload.table) ? action.payload.table : '',
            name: (action.payload.name) ? action.payload.name : '',
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { init } = userInfoSlice.actions

export default userInfoSlice.reducer