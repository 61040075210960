import { createSlice } from '@reduxjs/toolkit'

const initialState = [];

export const productHistorySlice = createSlice({
  name: 'productHistory',
  initialState,
  reducers: {
    addProductHistory: (state, action) => {
      return [...state, ...action.payload];
    }
  },
})

// Action creators are generated for each case reducer function
export const { addProductHistory } = productHistorySlice.actions

export default productHistorySlice.reducer