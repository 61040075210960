import React from 'react'
import Footer from '../components/Footer/Footer'
import Nav from '../components/Nav/Nav'

export default function OrderLayout({ children }) {
    return (
        <div className='xs:text-[18px] md:text-[20px]'>
            <Nav />
            <main className='min-h-[calc(100vh-150px)]'>{children}</main>
            <Footer />
        </div>
    )
}
