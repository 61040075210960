import React from 'react'

export default function CommonModal({id = 'modal', show, children, onClose = () => {}}) {
    if (!show) return;

    return (
        <div id={id} role="modal" tabIndex="-1" className="fixed top-0 bottom-0 h-screen w-full bg-black bg-opacity-50 z-20 flex items-center justify-center bg-blur animate__animated animate__fadeIn animate__faster" onClick={onClose}>
            <div onClick={(e) => e.stopPropagation()}>{children}</div>
        </div>
    )
}
