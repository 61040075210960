export default function Loading({loading}) {
    if (!loading) return;
    return (
      <div className="w-full flex items-center justify-center py-32">
        <img
          alt="loading"
          className="rounded-full animate-[spin_2s_ease_infinite] w-12 h-12"
          src="/images/common/logo.webp"
        ></img>
      </div>
    );
  }