import React from "react";
import { useSelector } from "react-redux";

export default function CategoryBlock({
    image,
    selected = false,
    name = "Dish",
    onClick,
}) {
    const appInfo = useSelector((state) => state.appInfo);
    image = image ? image : `/images/${appInfo.storeId}/brand-logo.webp`;
    return (
        <button
            className={`
                    relative border-y-2 bg-2
                    w-[50%] xs:w-[33%] md:w-[20%] lg:w-[calc(100%/6)] xl:w-[calc(100%/7)] 
                    min-w-[50%] xs:min-w-[33%] md:min-w-[20%] lg:min-w-[calc(100%/6)] xl:min-w-[calc(100%/7)] 
                    hover:opacity-80 
                    ${selected ? "border-red-600 bg-red-100" : "border-gray-100 bg-white"
                }`}
            onClick={onClick}
        >
            <div className="w-full h-[60px]">
                <img
                    className={`w-[50px] h-[50px] my-2 rounded-full mx-auto border p-[1px] ${selected ? "border-red-600" : "border-gray-100"
                        }`}
                    src={image}
                    alt={name}
                ></img>
            </div>
            <div
                className={`text-[12px] md:text-[16px] h-[60px] text-center font-bold uppercase px-2 ${selected ? "text-red-600" : "text-gray-900"
                    }`}
            >
                {name}
            </div>
        </button>
    );
}
