import React from "react";
import ProductHistoryCard from "../Product/ProductHistoryCard";

export default function ProductHistoryList({ productHistory = [] }) {
    let isRenderedOrderNo = 0;
    return (
        <div id="product-history-list" className="max-h-[75vh] overflow-auto">
            {productHistory.map((product, index) => {
                if (product.orderNo !== isRenderedOrderNo) {
                    isRenderedOrderNo = product.orderNo;
                    return (
                        <React.Fragment key={product.materialId}>
                            <div className="px-5 py-2 bg-red-600 text-white">
                                <div>Lần order thứ {product.orderNo}</div>
                                <div>Thời điểm: {new Date(product.createdTimestamp).toLocaleString()}</div>
                            </div>
                            <ProductHistoryCard
                                name={product.itemName}
                                image={product.image}
                                unitPrice={product.unitPrice}
                                quantity={product.itemQuantity}
                                orderNo={product.orderNo}
                                description={product.description}
                            />
                        </React.Fragment>);
                }

                return (<ProductHistoryCard
                    key={product.materialId}
                    name={product.itemName}
                    image={product.image}
                    unitPrice={product.unitPrice}
                    quantity={product.itemQuantity}
                    orderNo={product.orderNo}
                />)
            })}
        </div>
    );
}
