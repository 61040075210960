import { configureStore } from '@reduxjs/toolkit';
import categoriesWithProductsSlice from '../reducers/Product/CategoriesWithProductsSlice';
import productsOrderingSlice from '../reducers/Product/ProductsOrderingSlice';
import userInfoSlice from '../reducers/User/UserInfoSlice';
import appInfoSlice from '../reducers/App/AppInfoSlice';
import productsInCartSlice from '../reducers/Product/ProductsInCartSlice';
import productHistorySlice from '../reducers/Product/ProductHistorySlice';
import orderCompletedInfoSlice from '../reducers/Order/OrderCompletedInfoSlice';
import productsGroupOrderingSlice from '../reducers/Product/ProductsGroupOrderingSlice';

export const store = configureStore({
  reducer: {
    // Chứa categories, mỗi category chứa products tương ứng. Xem chi tiết flow render ở file docs.txt
    categoriesWithProducts: categoriesWithProductsSlice,
    // Chứa product đang được chọn để chuẩn bị vào cart
    productOrdering: productsOrderingSlice,
    // Chứa thông tin user. Hiện tại chưa dùng đến reducer này
    userInfo: userInfoSlice,
    // Chứa thông tin app. Bao gồm tên bàn, appID, storeID.
    appInfo: appInfoSlice,
    // Chứa products trong cart
    productsInCart: productsInCartSlice,
    // Chứa lịch sử order của user
    productHistory: productHistorySlice,
    // Chứa thông tin order đã hoàn thành, dùng để hiển thị trong OrderCompletedModal
    orderCompletedInfo: orderCompletedInfoSlice,
    // Chứa sản phẩm theo group đang được chọn để chọn làm productOrdering
    // Xem chi tiết ở Flow khi ấn chọn một sản phẩm trên màn hình home trong file docs.txt
    productsGroupOrdering: productsGroupOrderingSlice
  },
})