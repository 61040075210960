import { createSlice } from '@reduxjs/toolkit'

const initialState = {appId: null, storeId: null, tableStr: null};

export const appInfoSlice = createSlice({
  name: 'appInfo',
  initialState,
  reducers: {
    initAppInfo (state, action) {
      return {...state, ...action.payload};
    },
  },
})

// Action creators are generated for each case reducer function
export const { initAppInfo } = appInfoSlice.actions

export default appInfoSlice.reducer